<template>
  <template-layout :breadCrumbList="breadcrumbComputed" v-if="question_data">
    <div class="py-12 px-2 sm:px-4">
      <nav class="flex justify-between items-baseline mb-6">
        <h1 class="text-2xl text-primary-2">
          {{question_data?.name}}
        </h1>
        <div class="flex items-baseline">
          <router-link
            :to="{ name: 'survey-page', params:{survey: `${question_data?.name_en}`} }"
            class="btn btn-primary bg-primary-btn border-primary-btn mr-2 mb-2"
            v-if="isVisible"
          >
          + ทำแบบสอบถาม
          </router-link>
          <div class="flex gap-x-2" v-if="onlineComputed">
            <div class="dropdown">
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm hidden sm:inline-flex items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                ออกรายงาน [CSV] <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm inline-flex sm:hidden items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                CSV <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadCsv='code'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบรหัส
                  </button>
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadCsv='desc'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบคำอธิบาย
                  </button>
                </div>
              </div>
            </div>
            <div class="dropdown">
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm hidden sm:inline-flex items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                ออกรายงาน [Excel] <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm inline-flex sm:hidden items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                Excel <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadExcel='code'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบรหัส
                  </button>
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadExcel='desc'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบคำอธิบาย
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div class="grid grid-cols-12 gap-6">
        <!-- <div class="col-span-12">
          <Filter v-if="onlineComputed"/>
        </div> -->
        <div class="col-span-12">
          <Datatable :downloadCsv="downloadCsv" @downloadCsvComplete="downloadCsv = null" :downloadExcel="downloadExcel" @downloadExcelComplete="downloadExcel = null" :questionnaireData="question_data" v-if="onlineComputed" />
        </div>
      </div>
    </div>
  </template-layout>
</template>

<script setup>
import Datatable from "./Datatable.vue"
import { computed, reactive, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import { getPermissionAccessMenu } from "@/utils/getPermission";
import { useRoute } from "vue-router";
import axios from "axios";


const downloadCsv = ref(null)
const downloadExcel = ref(null)
const route = useRoute()

const store = useStore(); 
const token = store.getters["userAuth/getToken"];
const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
};
const question_data = ref(null)
// const breadcrumbComputed = reactive([{
//   name: "แบบสอบถาม",
//   active: true
// }])
const onlineComputed = computed(()=> store.getters["network/isOnline"])
const getSurveyData = async () => {
  const url = `${process.env.VUE_APP_BASE_API}admin/question_naire`;
  const params = {
    name_en: route.params.survey,
    status: "active"
  }
  const res = await axios.get(url, { headers, params }).catch(() =>
      Swal.fire({
          icon: "warning",
          text: "ระบบผิดพลาด",
      })
  );
  if(res.status === 200) {
    question_data.value = res.data[0]
  }
}
const breadcrumbComputed = computed(()=>{
  let temp = []
  if(question_data.value){
    temp.push({
      name: question_data.value.name,
      active: true
    })
  }
  return temp
})

onMounted(()=>{
  getSurveyData()
})
watch(() =>route.params, async (val)=>{
  if(val && onlineComputed.value){
    await getSurveyData()
  }
})

const isVisible = computed(() => {
  if ((store.getters["userAuth/getUserTumbolCode"].length != 6 && !onlineComputed.value) || (!getPermissionAccessMenu("create") && onlineComputed.value)) {
    return false;
  } else {
    return true;
  }
});
</script>
