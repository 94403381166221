<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <!-- <a
        href 
        class="flex mr-auto"
      >
        <img
          alt="Icewall Tailwind HTML Admin Template" 
          class="w-6"
          src="@/assets/images/logo.svg"
        >
      </a> -->
      <router-link
        :to="{ name: 'main-house-hold' }"
        class="flex mr-auto items-center"
      >
        <div class="w-32">
          <img src="@/assets/images/main/Logo-Local-census.png" />
        </div>
      </router-link>
      <BarChart2Icon
        class="w-8 h-8 text-primary transform -rotate-90"
        @click="toggleMobileMenu"
      />
    </div>
    <transition
      @enter="enter"
      @leave="leave"
    >
      <ul
        v-if="activeMobileMenu"
        class="border-t border-theme-2 py-5"
      >
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu" :key="menu + menuKey">
          <li
            v-if="menu.key == 'devider'"
            class="menu__devider my-6"
          />
          <li
            v-else
          >
            <a
              :href="
                menu.subMenu
                  ? 'javascript:;'
                  : menu.params ? router.resolve({ name: menu.pageName, params: {...menu.params} }).path : router.resolve({ name: menu.pageName }).path
              "
              class="menu"
              :class="{
                'menu--active': menu.active,
                'menu--open': menu.activeDropdown
              }"
              @click="linkTo(menu, router, $event)"
            >
              <div class="menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="menu__title">
                {{ menu.title }}
                <div
                  v-if="menu.subMenu"
                  class="menu__sub-icon"
                  :class="{ 'transform rotate-180': menu.activeDropdown }"
                >
                  <ChevronDownIcon />
                </div>
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <transition
              @enter="enter"
              @leave="leave"
            >
              <ul v-if="menu.subMenu && menu.activeDropdown">
                <li
                  v-for="(subMenu, subMenuKey) in menu.subMenu"
                  :key="subMenuKey"
                >
                  <a
                    :href="
                      subMenu.subMenu
                        ? 'javascript:;'
                        : subMenu.params ? router.resolve({ name: subMenu.pageName, params: {...subMenu.params}}).path : router.resolve({ name: subMenu.pageName }).path
                    "
                    class="menu"
                    :class="{ 'menu--active': subMenu.active }"
                    @click="linkTo(subMenu, router, $event)"
                  >
                    <div class="menu__icon">
                      <!-- <ActivityIcon /> -->
                      <div class="w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 bg-white flex items-center justify-center" v-if="subMenu.image">
                        <img src="@/assets/images/user.png" class="object-cover object-center h-full w-full" v-if="subMenu.image == 'default'">
                        <img :src="subMenu.image" class="object-cover object-center h-full w-full" v-else>
                      </div>
                      <component :is="subMenu.icon" v-else-if="subMenu.icon"/>
                      <ActivityIcon v-else/>
                    </div>
                    <div class="menu__title">
                      {{ subMenu.title }}
                      <div
                        v-if="subMenu.subMenu"
                        class="menu__sub-icon"
                        :class="{
                          'transform rotate-180': subMenu.activeDropdown
                        }"
                      >
                        <ChevronDownIcon />
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Third Child -->
                  <transition
                    @enter="enter"
                    @leave="leave"
                  >
                    <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                      <template
                        v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                        :key="lastSubMenuKey">
                      <li
                        v-if="lastSubMenu == 'devider'"
                        class="menu__devider my-6"
                      >
                      </li>
                      <li
                        v-else
                      >
                        <a
                          :href="
                            lastSubMenu.subMenu
                            ? 'javascript:;'
                            : lastSubMenu.params ? router.resolve({ name: lastSubMenu.pageName, params: {...lastSubMenu.params}}).path : router.resolve({ name: lastSubMenu.pageName }).path
                          "
                          class="menu"
                          :class="{ 'menu--active': lastSubMenu.active }"
                          @click="linkTo(lastSubMenu, router, $event)"
                        >
                          <div class="menu__icon">
                            <component :is="lastSubMenu.icon" v-if="lastSubMenu.icon"/>
                            <ActivityIcon v-else/>
                            <!-- <ZapIcon /> -->
                          </div>
                          <div class="menu__title">
                            {{ lastSubMenu.title }}
                          </div>
                        </a>
                      </li>
                    </template>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </transition>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave
} from './index'
import { nestedMenu } from '@/layouts/side-menu'

export default defineComponent({
  props: {
    customMenu: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const mobileMenu = computed(() =>{
      const menuList = store.getters["sideMenu/menu"].reduce((acc, menu, index) => {
          let temp = []
          if(menu.isAdmin && props.customMenu.length){
            temp = [...temp, ...props.customMenu, menu]
          }else{
            temp = [...temp, menu]
          }
          if(store.getters["sideMenu/menu"].length - 1 == index && props.customMenu.length && !store.getters["sideMenu/menu"].some(s=> s.isAdmin)){
            temp = [...temp, ...props.customMenu]
          }

          return [...acc, ...temp];
      }, []);
      return nestedMenu(menuList, route)
    })

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value)
      }
    )
    watch(
      computed(() => props.customMenu),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value);
      }
    );

    onMounted(() => {
      formattedMenu.value = $h.toRaw(mobileMenu.value)
    })

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    }
  }
})
</script>
