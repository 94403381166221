<template>
  <template-layout :breadCrumbList="breadcrumbComputed">
    <div class="py-12 px-2 sm:px-4">
      <nav class="flex justify-between items-baseline mb-6 flex-wrap">
        <h1 class="text-2xl text-primary-2">ครัวเรือน</h1>
        <div class="flex items-baseline">
          <router-link
            :to="{ name: 'add-household' }"
            class="btn btn-primary bg-primary-btn border-primary-btn mr-2 mb-2"
            v-if="isVisible"
          >
          + เพิ่มครัวเรือน
          </router-link>
          <div class="flex gap-x-2" v-if="onlineComputed">
            <div class="dropdown">
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm hidden sm:inline-flex items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                ออกรายงาน [CSV] <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm inline-flex sm:hidden items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                CSV <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadCsv='code'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบรหัส
                  </button>
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadCsv='desc'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบคำอธิบาย
                  </button>
                </div>
              </div>
            </div>
            <div class="dropdown">
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm hidden sm:inline-flex items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                ออกรายงาน [Excel] <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm inline-flex sm:hidden items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                Excel <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadExcel='code'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบรหัส
                  </button>
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadExcel='desc'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบคำอธิบาย
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="relative ml-auto sm:ml-0 block sm:hidden bottom-[-5px] mb-2" v-if="onlineComputed">
            <div class="dropdown">
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 rounded-md font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                <MoreVerticalIcon class="w-5 h-5"/>
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadCsv=true">
                    <DownloadIcon class="h-3 w-3 mr-2" /> CSV
                  </button>
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadExcel=true">
                    <DownloadIcon class="h-3 w-3 mr-2" /> Excel
                  </button>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </nav>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12" v-if="onlineComputed">
          <HouseholdFilter :filter="filterSummaryStore" />
        </div>
        <div class="col-span-12">
          <HouseholdDatatable :filter="filterValue" :downloadCsv="downloadCsv" @downloadCsvComplete="downloadCsv = null" :downloadExcel="downloadExcel" @downloadExcelComplete="downloadExcel = false" v-if="onlineComputed"/>
          <OfflineDatatable :data-array="dataOfflineList" v-else />
        </div>
      </div>
    </div>
  </template-layout>
</template>
<script setup>
import HouseholdFilter from "@/components/filter-search/HouseholdFilter.vue";
import HouseholdDatatable from "./Datatable.vue";
import OfflineDatatable from "./OfflineDatatable.vue"
import { ref, computed, watch, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import TemplateLayout from "@/layouts/side-menu/Main.vue";
import localForage from "localforage"
import { getPermissionAccessMenu } from "@/utils/getPermission"

const store = useStore();
const filterSummaryStore = computed(
  () => store.getters["householdFilter/getFilterSummary"]
);
const filterValue = computed(
  () => store.getters["householdFilter/getFilterForApi"]
);

const reloadValue = computed(
  () => true
);
const dataOfflineList = ref([])
const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const onlineComputed = computed(()=>store.getters["network/isOnline"])
watch(onlineComputed, () =>{
  if(!onlineComputed.value && store.getters["userAuth/getUserTumbolCode"].length == 6){
    getOfflineData()
  }
})
onMounted(()=>{
  if(!onlineComputed.value && store.getters["userAuth/getUserTumbolCode"].length == 6){
    getOfflineData()
  }
})
const getOfflineData = () =>{
  houseStore.keys()
  .then(function(keys){
      var matches = []
      for(let i in keys) {
        matches.push(houseStore.getItem(keys[i]))
      }
      // Promise.all returns a promise that resolves to an array 
      // of the values they resolve to
      return Promise.all(matches)
  })
  .then(values => {
      // values is an array of your items
      // return values
      dataOfflineList.value = values
  })
}


const reloadTable = ref(false);
const downloadCsv = ref(null)
const downloadExcel = ref(null)


const isUser = computed(() => {
  if (store.getters["userAuth/getUserProvinceCode"] !== "00") {
    return true;
  } else {
    return false;
  }
});
const breadcrumbComputed = reactive([{
  name: "ครัวเรือน",
  active: true
}])
const isVisible = computed(() => {
  if ((store.getters["userAuth/getUserTumbolCode"].length != 6 && !onlineComputed.value) || (!getPermissionAccessMenu("create") && onlineComputed.value)) {
    return false;
  } else {
    return true;
  }
});
</script>

<style scoped>
</style>
