<template>
  <template-layout :customMenu="customMenu" :breadCrumbList="breadcrumbComputed">
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h1 class="text-lg font-medium mr-auto">
          {{questionnaire_data.questionnaire_name}}
        </h1>
      </div>
      <div class="w-1/6 h-1/6 mx-auto" v-if="loadingQuestion && (isView ? loadingMember : loadingQuestion)">
        <LoadingIcon icon="oval" class="w-8 h-8 text-white" />
      </div>
      <div v-else>
        <div class="intro-y box pb-10 pt-10 sm:pb-20 sm:pt-8 mt-5">
          <surveyWizard :surveyData="surveyData" :isView="isView" :isEdit="isEdit" @exit-form="exitForm" @complete-form="completeForm" :questionList="question_data?.[0].data" :questionnaireData="questionnaire_data"/>
        </div>
      </div>
    </div>
  </template-layout>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import surveyWizard from "@/components/survey-wizard/index.vue"
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from "axios"
import Swal from "sweetalert2";

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});

const route = useRoute()
const router = useRouter()
const store = useStore()
const surveyData = ref(null)
const isView = ref(false)
const isEdit = ref(false)
const customMenu = ref([])
const loadingMember = ref(false)
const isPromptRouteLeave = ref(true)
const loadingQuestion = ref(false)
const question_data = ref(null)
const questionnaire_data = ref({})

const getSurveyData = async() =>{
  const token = store.getters["userAuth/getToken"];
  const url = `${process.env.VUE_APP_BASE_API}questionResults/${route.params.surveyid}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
  };
  const res = await axios.get(url, { headers }).catch(() =>{
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    })
  }
  );
  if (res.status === 200) {
    surveyData.value = res.data
  }
}

const getQuestionList = async () => {
    const token = store.getters["userAuth/getToken"];
    const url = `${process.env.VUE_APP_BASE_API}admin/question`;
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json; charset=utf-8`,
    };
    const params = {
      question_nare_name_en: route.params.survey
    }
    loadingQuestion.value = !loadingQuestion.value
    const res = await axios.get(url, { headers, params }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        question_data.value = res.data
        questionnaire_data.value = {
          questionnaire_name: res.data?.[0].question_naire_name,
          questionnaire_name_en: res.data[0].question_naire_name_en,
          questionnaire_name_short: res.data[0].question_naire_short_name,
          questionnaire_id: res.data[0].question_naire_id,
          code_name: res.data[0].code_name,
        }
        loadingQuestion.value = !loadingQuestion.value
    }
}

onMounted(async ()=>{
    await getQuestionList()
    if(route.name === "view-edit-survey"){
      if(route.params.actionEvent === "view"){
        console.log("view");
        
        isView.value = true
        isPromptRouteLeave.value = false
      }
      else if(route.params.actionEvent === "edit"){
        console.log("edit");
        
        isEdit.value = true
      }
      await getSurveyData()
    }
})

const breadcrumbComputed = computed(()=>{
  let array = [
    {
      name: "แบบสอบถาม",
      path: "publish-survey",
    }
  ];
  return array;
})
const completeForm = (backStatus=null) => {
    isPromptRouteLeave.value = false
    if(backStatus){
      router.go(-1)
    }
}
const exitForm = () =>{
  router.go(-1)
}
onBeforeRouteLeave((to, from, next) => {
  if(isPromptRouteLeave.value){
    swalCustom.fire({
      html: isView.value ? `<div>คุณแน่ใจที่จะออกจากหน้านี้</div>` : `<div>ข้อมูลจะไม่ถูกบันทึก</div>
          <div>คุณแน่ใจที่จะออกจากหน้านี้</div>`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        next()
      } else {
        next(false)
      }
    })
  }
  else{
    next()
  }
})
</script>

<style lang="scss">
</style>