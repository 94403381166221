import store from "@/store/index";
import axios from "axios";

const fetchMenu = () => {
    return new Promise(async(resolve) => {
        const config = {
            url: `${process.env.VUE_APP_BASE_API}admin/question_naire`,
            method: "GET",
            params:{
                status: "active"
            },
            headers: {
                Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
                "Content-Type": `application/json; charset=utf-8`,
            }
        };
        axios(config)
        .then((res)=>{
            resolve(res.data)
        })
        .catch(()=>{
            resolve([])
        })
    })
}
function getPermissionByLevel(){
    return new Promise(async(resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_API}managePermission`
        let roles = store.getters["userAuth/getRole"]
        let allow_access_route = []
        let allow_action = []
        if(roles?.includes("mpi_admin")){
            allow_access_route = [{name: "survey-datatable"}, {name: "house-survey-management"}, {name: "member-survey-management"}, {name: "neets-survey-management"}, {name: "cwd-survey-management"}, {name: "permission-management"}, {name: "add-survey-management"}, {name: "survey-management"}, {name: "edit-survey-management"}]
            allow_action = ["read"]
        }
        else{
            const level = store.getters["userAuth/getAreaCode"]
            let level_name = null
            if(level.length == 2 && level != "00"){
                level_name = "ระดับจังหวัด"
            }
            else if(level.length == 4){
                level_name = "ระดับอำเภอ"
            }
            else if(level.length == 6){
                level_name = "ระดับตำบล"
            }
            else if(level == "00"){
                level_name = "ระดับประเทศ"
            }
            const config = {
            url: url,
            method: "GET",
            headers: {
                Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
                "Content-Type": `application/json; charset=utf-8`,
            }
            };
            if(level_name){
                config["params"] = {
                    level_name: level_name
                }
            }
            const response = await axios(config)
            .catch((error) => {
                reject(error);
            });
            if(response.status == 200){
                if(response.data.accessible_menus){
                    const patternSort = {"household": 1, "member": 2, "neets": 3, "cwd": 4, "dashboard": 5}

                    for(let menu of Object.keys(response.data.accessible_menus).sort((x,y)=> patternSort[x] - patternSort[y])){
                        if(response.data.accessible_menus[menu] && response.data.permissions?.read ){
                            if(menu == "household"){
                                allow_access_route.push({name: "main-house-hold"}, {name: "household-profile"}, {name: "view-household"})
                                if(response.data.permissions?.create){
                                    allow_access_route.push({name: "add-household"}, {name: "add-household-survey"}, {name: "sync-page"})
                                }
                                if(response.data.permissions?.update){
                                    allow_access_route.push({name: "edit-household"})
                                }
                            }
                            else if(menu == "member"){
                                allow_access_route.push({name: "member-page"}, {name: "view-member"})
                                if(response.data.permissions?.create){
                                    allow_access_route.push({name: "add-member"})
                                }
                                if(response.data.permissions?.update){
                                    allow_access_route.push({name: "edit-member"})
                                }
                            }
                            else if(menu == "neets"){
                                allow_access_route.push({name: "neets"}, {name: "view-neet"})
                                if(response.data.permissions?.create){
                                    allow_access_route.push({name: "add-neet"})
                                }
                                if(response.data.permissions?.update){
                                    allow_access_route.push({name: "edit-neet"})
                                }
                            }
                            else if(menu == "cwd"){
                                allow_access_route.push({name: "cwd"}, {name: "view-cwd"})
                                if(response.data.permissions?.create){
                                    allow_access_route.push({name: "add-cwd"})
                                }
                                if(response.data.permissions?.update){
                                    allow_access_route.push({name: "edit-cwd"})
                                }
                            }
                            else if(menu == "dashboard"){
                                allow_access_route.push({name: "dashboard-overview"}, {name: "dashboard-summary"})
                            }
                            else{
                                allow_access_route.push({name: "publish-survey", params: {survey: menu}})
                                if(response.data.permissions?.create){
                                    allow_access_route.push({name: "survey-page", params: {survey: menu}})
                                }
                                if(response.data.permissions?.read){
                                    allow_access_route.push({name: "view-edit-survey", params: {actionEvent: "view", survey: menu}})
                                }
                                if(response.data.permissions?.update){
                                    allow_access_route.push({name: "view-edit-survey", params: {actionEvent: "edit", survey: menu}})
                                }
                            }
                        }
                    }
                    //allow action
                    if(response.data.permissions?.read){
                        allow_action.push("read")
                    }
                    if(response.data.permissions?.create){
                        allow_action.push("create")
                    }
                    if(response.data.permissions?.update){
                        allow_action.push("update")
                    }
                    if(response.data.permissions?.delete){
                        allow_action.push("delete")
                    }
                    if(response.data.permissions?.export){
                        allow_action.push("export")
                    }
                    if(!response.data.permissions?.read || Object.keys(response.data.accessible_menus).every(menu => !response.data.accessible_menus[menu])){
                        allow_access_route = [{name: "error-page"}]
                    }
                }
            }
        }
        const menu_result = await fetchMenu()
        store.dispatch("sideMenu/setPermissionToMenu", {menu: menu_result, route: allow_access_route})
        store.dispatch("permission/setAllowRouteName", allow_access_route)
        store.dispatch("permission/setAllowActions", allow_action)
        resolve(allow_access_route);
    });
}

function getPermissionAccessMenu(action){
    return store.getters["permission/allowActions"].includes(action)
}
export { getPermissionByLevel, getPermissionAccessMenu };