<template>
  <div class="overflow-x-auto scrollbar-hidden" data-test="all-survey-datatable">
    <Datatable :api-params="paramsComputed" :api-url="'admin/paging/question_naire'" :init-sorter="[{filed:'_id', dir: 'asc'}]" :method-request="'GET'" :data-array-table="dataArrayTable" :key="key" @rowClicked="handleRowClick" />
  </div>
</template>

<script setup>
import Datatable from "@/components/datatable/index.vue"
import { reactive, ref, computed } from "vue"
import Swal from 'sweetalert2'
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios"
import { getPermissionAccessMenu } from "@/utils/getPermission"

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});

const dataArray = ref()
const params = ref({start: 1,draw: 10,length: 10})
const key = ref(1)
const downloadFile = ref(false)
const currentFileType = ref("")
const router = useRouter()
const store = useStore()
const headers = {
  Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
  "Content-Type": `application/json; charset=utf-8`,
};
const paramsComputed = computed(() => ({...params.value}))
const dataArrayTable = reactive([
{title:"สถานะ", field:"status", hozAlign:"center", resizable:true, minWidth: 120, width: 120,
    formatter:function(cell, formatterParams){
      const updateStatusCallback = (e) =>{
        //prevent default row click
        e.stopPropagation();
        let infoText = ""
        if(e.target.checked && !cell.getData().flag_is_activated){
          infoText = "<div><strong>หากเปิดการใช้งานแล้ว เลขข้อคำถาม<br/>ที่เพิ่มใหม่อาจจะไม่เรียงลำดับ</strong></div>"
        }
        swalCustom
        .fire({
            html: `${infoText}<div>ยืนยัน ${e.target.checked ? `เปิดการใช้งาน` : `ปิดการใช้งาน`}?</div>`,
            icon: "info",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            allowOutsideClick: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let params_config = {
              status: e.target.checked ? "active" : "inactive",
            }
            if(e.target.checked && !cell.getData().flag_is_activated){
              params_config["code_name"] = cell.getData().code_name
            }
            const res = await axios.put(
              `${process.env.VUE_APP_BASE_API}admin/question_naire/${cell.getData()._id}`,
              {
                ...params_config
              },
              {
                headers: {
                  Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
                  "Content-Type": `application/json; charset=utf-8`,
                }
              }
            ).catch(() => {
              swalCustom.fire({
                text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                icon: "warning",
                //timer: 3000, //timeOut for auto-close
                confirmButtonText: "ปิด",
              });
            })
            if(res.status == 200){
              key.value++
            }
          }
          if(result.dismiss === Swal.DismissReason.cancel){
            e.target.checked = !e.target.checked
          }
        });
      }
      let input = document.createElement("input");
      input.setAttribute("type", "checkbox");
      input.className = "form-check-switch"
      if(cell.getValue() == "active")
        input.checked = true
      input.addEventListener("click", updateStatusCallback);
      if(cell.getData().name_en == "household" || cell.getData().name_en == "member" || cell.getData().name_en == "cwd" || cell.getData().name_en == "neets")
        input.setAttribute("disabled","disabled")
      const b = cash(input)
      return b[0]
    }
  },
  {title:"ชื่อแบบสอบถาม", field:"name", hozAlign:"left", formatter:"textarea", minWidth: 200, width: 220, resizable:true},
  {title:"ชื่อแบบสอบถามแบบย่อ", field:"short_name", hozAlign:"center", minWidth: 150, width: 200, variableHeight:true,
    formatter:function(cell){
      return cell.getValue()
    }
  },
  {title:"ชื่อแบบสอบถามภาษาอังกฤษ", field:"name_en", hozAlign:"center", minWidth: 230, width: 230, variableHeight:true,
    formatter:function(cell){
      return cell.getValue()
    }
  },
  {title:"ชื่อรหัสคำถาม", field:"code_name", hozAlign:"center", minWidth: 100, width: 200, variableHeight:true,
    formatter:function(cell){
      return cell.getValue()
    }
  },
  {title:"การดำเนินการ", field:"action", hozAlign:"center", minWidth: 150, width: 200,
    formatter:function(cell, formatterParams){
      const editCallback = (e) => {
        //prevent default row click
        e.stopPropagation();
        if(cell.getData().name_en){
          router.push({
            name: "edit-survey-management",
            params:{
              surveyid: cell.getData().name_en
            }
          })
        }
      };
      const deleteCallback = (e) =>{
        //prevent default row click
        e.stopPropagation();
        swalCustom.fire({
          text:"ยืนยันลบข้อมูล",
          icon:"warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'ตกลง',
          cancelButtonText: 'ยกเลิก',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await axios.delete(`${process.env.VUE_APP_BASE_API}admin/question_naire/${cell.getData()._id}`, {headers}).catch(() =>{
              swalCustom.fire({
                text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                icon: "warning",
                timer: 3000, //timeOut for auto-close
                confirmButtonText: "ปิด",
              });
            });
            if(res.status == 200){
              swalCustom.fire({
                text: "สำเร็จ",
                icon: "success",
                timer: 3000, //timeOut for auto-close
                confirmButtonText: "ปิด",
              }).then(() => {
                key.value++
              });
            }
          }
        })
      }
      const viewEvent = (e) =>{
        //prevent default row click
        e.stopPropagation();
        if(cell.getData().name_en == "household"){
          router.push({
            name: "house-survey-management",
          })
        }
        else if(cell.getData().name_en == "member"){
          router.push({
            name: "member-survey-management",
          })
        }
        else if(cell.getData().name_en == "neets"){
          router.push({
            name: "neets-survey-management",
          })
        }
        else if(cell.getData().name_en == "cwd"){
          router.push({
            name: "cwd-survey-management",
          })
        }
        else{
          router.push({
            name: "survey-management",
            params:{
              survey: cell.getData().name_en
            }
          })
        }
      }

      let divElm = document.createElement("div")
      divElm.className = "flex lg:justify-center items-center gap-2"

      let editDiv = document.createElement("div");
      editDiv.className = "t-tooltip flex justify-center w-7 h-7"
      let editBtn = document.createElement("button")
      editBtn.className = "btn btn-secondary bg-primary-3/[0.7] hover:bg-primary-3/[0.6] text-white mr-1 border-0 rounded w-7 h-7 p-1"
      let editText = document.createElement("span");
      editText.className = "t-tooltip-text"
      editText.append("แก้ไข")
      let icon = document.createElement("i");
      icon.setAttribute("data-feather","edit")
      icon.className = "w-7 h-7"
      editBtn.addEventListener("click", editCallback);
      editBtn.append(icon);
      editDiv.append(editText)
      editDiv.append(editBtn)
      divElm.append(editDiv)

      let viewDiv = document.createElement("div");
      viewDiv.className = "t-tooltip flex justify-center w-7 h-7"
      let viewBtn = document.createElement("button");
      viewBtn.className = "btn btn-secondary bg-primary-2/[0.8] hover:bg-primary-2/[0.65] text-white mr-1 border-0 rounded w-7 h-7 p-1"
      let viewText = document.createElement("span");
      viewText.className = "t-tooltip-text"
      viewText.append("แสดง")
      let iconView = document.createElement("i");
      iconView.setAttribute("data-feather","eye")
      iconView.className = "w-7 h-7"

      viewBtn.addEventListener("click", viewEvent);
      viewBtn.append(iconView);
      viewDiv.append(viewText)
      viewDiv.append(viewBtn)
      divElm.append(viewDiv)

      let delDiv = document.createElement("div");
      delDiv.className = "t-tooltip flex justify-center w-7 h-7"
      let delBtn = document.createElement("button")
      delBtn.className = "btn btn-secondary bg-theme-24/[0.8] hover:bg-theme-24/[0.5] text-white mr-1 border-0 rounded w-7 h-7 p-1"
      let delText = document.createElement("span");
      delText.className = "t-tooltip-text"
      delText.append("ลบ")
      let delIcon = document.createElement("i");
      delIcon.setAttribute("data-feather","trash-2")
      delIcon.className = "w-7 h-7"
      delBtn.addEventListener("click", deleteCallback);
      if(cell.getData().name_en == "household" || cell.getData().name_en == "member" || cell.getData().name_en == "cwd" || cell.getData().name_en == "neets")
        delBtn.setAttribute("disabled","disabled")
      delBtn.append(delIcon)
      delDiv.append(delText)
      delDiv.append(delBtn)
      divElm.append(delDiv)

      const b = cash(divElm)
      return b[0]
    }
  },
])

const handleRowClick = (row) =>{
    if(getPermissionAccessMenu("read")){
      if(row.name_en == "household"){
        router.push({
          name: "house-survey-management",
        })
      }
      else if(row.name_en == "member"){
        router.push({
          name: "member-survey-management",
        })
      }
      else if(row.name_en == "neets"){
        router.push({
          name: "neets-survey-management",
        })
      }
      else if(row.name_en == "cwd"){
        router.push({
          name: "cwd-survey-management",
        })
      }
      else{
        router.push({
          name: "survey-management",
          params:{
            survey: row.name_en
          }
        })
      }
    }
}

</script>

